@font-face {
  font-family: "Gotham";
  src: url("Gotham/GothamPro-Bold.otf") format("truetype");
  src: url("Gotham/GothamPro-Bold.woff") format("woff");
  src: url("Gotham/GothamPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham/Gotham Regular.otf") format("truetype");
  src: url("Gotham/Gotham Regular.woff") format("woff");
  src: url("Gotham/Gotham Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham/Gotham-Medium.otf") format("truetype");
  src: url("Gotham/Gotham-Medium.woff") format("woff");
  src: url("Gotham/Gotham-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: "Gotham", Arial, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}
